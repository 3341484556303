import { atom, useAtom } from "jotai";
import Axios from 'axios';
import { environment } from "../../environment";
import { errorCatcher, useSnackbar } from "../../hooks/useSnackbar";
import { useLogin } from "../login/login";
import { CreateHeaderRequestBody } from "../product/product";

export interface CreateDesignRequestBody {
    brand: string;
    product_id: number;
    art_type: string;
}

export interface ApplyPatternRequestBody {
    brand_id: number;
    product_id: string;
    mockup_ids: string;
    store_id: string;
}

export interface CreateSampleRequestBody {
    coordinates: {
        x: number;
        y: number;
        width: number;
        height: number;
    };
    design_id: number;
}

export interface Notification {
    id: number;
    created_at: string;
    passthrough_data: CreateDesignRequestBody | ApplyPatternRequestBody | CreateSampleRequestBody | CreateHeaderRequestBody;
    category: 'MOCKUP_UPLOAD' | 'SAMPLE_CREATION' | 'PRODUCT_UPLOAD' | 'HEADER_CREATION';
    status_message: string;
    status: 'unprocessed' | 'processing' | 'processed' | 'failed';
    brand_id: number;
}

export const notificationsAtom = atom<Notification[]>([]);
export const notificationsLoadingAtom = atom<boolean>(false);

type SetMockupCatoryType = (payload: Notification[]) => void;

type FetchMockupCategories = () => void;

type NotificationKeyFunctions = {
    get: FetchMockupCategories
}

type UseNotifications = [
    Notification[],
    SetMockupCatoryType,
    NotificationKeyFunctions
];

export const useNotifications = (brand: number): UseNotifications => {
    const [notifications, setNotifications] = useAtom(notificationsAtom);
    const [,setActionLoading] = useAtom(notificationsLoadingAtom);

    const [,setSnackbar] = useSnackbar();
    const [,authToken, loginFunctions] = useLogin();

    const getMockupCategories = () => {
        const fetchNotifications = async (setter: SetMockupCatoryType): Promise<void> => {
            setActionLoading(true);
            const result = await Axios.get(`${environment.queuesUrl}/image-processor?brand_id=${brand}`, {
                headers: {
                    'X-Wallmates-Auth': authToken
                }
            }).catch((err: any) => errorCatcher(err, loginFunctions.logout));
            if (result) {
                setter(result.data);
            } else {
                setSnackbar({
                    show: true,
                    snackbarLevel: 'error',
                    text: 'There was an error while getting Notifications. Please try again later.'
                })
            }
            setActionLoading(false);
        }
        fetchNotifications(setNotifications);
    }

    const keyFunctions: NotificationKeyFunctions = {
        get: getMockupCategories
    }

    return [
        notifications,
        setNotifications,
        keyFunctions
    ]
}